/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // show specific media field on page named "Homepage"
        var page_name = $('#titlewrap input').attr('value');

        // if (page_name !== 'Homepage'){
        //   $('#fm_meta_box_hero_slides').hide();
        // }

        var homeboxes = $('#fm_meta_box_home_galleries, #fm_meta_box_home_how_tos, #fm_meta_box_home_formulas, #fm_meta_box_home_celebrities, #fm_meta_box_home_collections, #fm_meta_box_home_shop_items, #fm_meta_box_home_product_groupings, #fm_meta_box_home_runways');

        if ($('#page_template').val() != 'homepage.php'){
          homeboxes.hide();
        }

        $('#page_template').on('change', function(){
          if ($(this).val() == 'homepage.php') {
            homeboxes.show();
          }
          else {
            homeboxes.hide();
          }
        });

        $('.fm-hero_slides').css('border-bottom', '3px dotted black');

        //Article stuff
        if ($('#fm-article_checkbox-0-gallery').prop('checked')){
          $('#fm_meta_box_article_slides').css('max-height', '100%');
        }
        else {
          $('#fm_meta_box_article_slides').css('max-height', '0').css('overflow', 'hidden');
        }


        $('.fm-article_checkbox-wrapper input.fm-element').click(function(){
          $('#fm_meta_box_article_slides').css('max-height', '0').css('overflow', 'hidden');
        });
        $('#fm-article_checkbox-0-gallery').click(function(){
          $('#fm_meta_box_article_slides').css('max-height', '100%');
        });

        function open_media_window(e) {
            if (this.window === undefined) {
                this.window = wp.media({
                        title: 'Insert a media',
                        library: {type: 'image'},
                        multiple: false,
                        button: {text: 'Insert'}
                    });

                var self = this; // Needed to retrieve our variable in the anonymous function below
                this.window.on('select', function() {
                        var first = self.window.state().get('selection').first().toJSON();
                        wp.media.editor.insert('[btc-' + e.data.shortcode_type + ' id="' + first.id + '"]');
                    });
            }

            this.window.open();
            return false;
        }


        $('.insert-img-caption').click({ shortcode_type: 'img' }, open_media_window );

        $('.insert-banner-link').click({ shortcode_type: 'banner' }, open_media_window );


        function open_slideshow_media_window(e) {
            if (this.window === undefined) {
                this.window = wp.media({
                        title: 'Select images for the slideshow',
                        library: {type: 'image'},
                        multiple: true,
                        button: {text: 'Insert'}
                    });

                var self = this; // Needed to retrieve our variable in the anonymous function below
                this.window.on('select', function() {
                        var selections = self.window.state().get('selection').toJSON();

                        var id_strings = "";

                        var arrayLength = selections.length;
                        for (var i = 0; i < arrayLength; i++) {
                          if( i ==  0){
                            id_strings = id_strings + selections[i].id;
                          }else{
                            id_strings = id_strings + " " + selections[i].id;
                          }
                        }

                        wp.media.editor.insert('[btc-insert-slideshow ids="' + id_strings + '"]');
                    });
            }

            this.window.open();
            return false;
        }

        $('.insert-slideshow').click(open_slideshow_media_window );



        function open_revealer_media_window(e) {
            if (this.window === undefined) {
                this.window = wp.media({
                        title: 'Select 2 images for the revealer (Left first)',
                        library: {type: 'image'},
                        multiple: true,
                        button: {text: 'Insert'}
                    });

                var self = this; // Needed to retrieve our variable in the anonymous function below
                this.window.on('select', function() {
                        var first = self.window.state().get('selection').first().toJSON();
                        var second = self.window.state().get('selection').toJSON()[1];
                        wp.media.editor.insert('[btc-revealer left-id="' + first.id + '" right-id="' + second.id + '"]');
                    });
            }

            this.window.open();
            return false;
        }

        $('.insert-revealer').click(open_revealer_media_window);


        function open_options_media(e) {
          console.log(this);
          var thisButton = $(this);
          thisButton.siblings('.custom-message-input').val('');
            if (this.window === undefined) {
                this.window = wp.media({
                        title: 'Insert a media',
                        library: {type: 'image'},
                        multiple: false,
                        button: {text: 'Insert'}
                    });

                var self = this; // Needed to retrieve our variable in the anonymous function below
                // console.log(self);
                this.window.on('select', function() {
                        var first = self.window.state().get('selection').first().toJSON();
                        // wp.media.editor.insert('[btc-' + e.data.shortcode_type + ' id="' + first.id + '"]');
                        thisButton.siblings('.custom-message-input').val(first.url);
                        thisButton.siblings('.options-preview-img').attr('src', first.url);
                        thisButton.siblings('.custom-alt-input').val(first.alt);
                        // console.log(e);
                        // console.log(self);
                        console.log(first)
                        // console.log($('.options-media'));
                    });
            }

            this.window.open();
            return false;
        }

        $('.options-media').click(
          open_options_media
        );

        $('.remove-options-media').click(function(){
          $(this).siblings('.custom-message-input').val('');
          $(this).siblings('.custom-alt-input').val('');
          $(this).siblings('.options-preview-img').attr('src', '');
          return false;
        })


        function insert_products_used() {
            wp.media.editor.insert('[btc-products]');
          return false;
        }
        $('#insert-products_used').click(insert_products_used);

        function insert_credits() {
            wp.media.editor.insert('[btc-credits]');
          return false;
        }
        $('#insert-credits').click(insert_credits);


        var prevVal;
        var selectedVal = [];

        $('.fm-orderbox select option:selected').each(function(){
          selectedVal.push($(this).val());
        })

        $('.fm-orderbox select option').each(function(){

          thisVal = $(this).val();

          var beenSelected = $.inArray(thisVal, selectedVal) >= 0;
          // console.log(beenSelected);
          if (beenSelected && thisVal != 'Hide This Category') {
            $(this).prop('disabled', true);
          }
          if ($(this).is(':selected')){
            //make sure it doesn't have disabled and selected so it saves correctly
            $(this).removeProp('disabled');
          }

        })


        // On change of one dropdown
        $('.fm-orderbox select')
        .on('focus' , function(){
          // Grab the old value
          prevVal = $(this).children('option:selected').val();
          prevEl = $(this).children('option:selected');

          $(this).blur();

        })
        .change(function(){
          // Grab the new value
          var newVal = $(this).val();

          // selectedVal.splice( $.inArray(prevVal, selectedVal), 1 );
          prevEl.removeProp('disabled');
          // Loop through each input
          $('.fm-orderbox select').not(this).each(function(){
            // Add `disabled` to all instances of the new value
            if( newVal != 'Hide This Category' ){
              var childSelector = 'option[value=' + newVal + ']';

              $(this).find(childSelector).prop( 'disabled', true );
            }

            //Remove `disabled` from all instances of the old value in dropdowns
            if( prevVal != 'Hide This Category' ){
              var childSelector = 'option[value=' + prevVal + ']';

              $(this).find(childSelector).prop( 'disabled', false );
            }

          });

        });
      }
    }
  };
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.
